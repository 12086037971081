import forge from "node-forge"
import { setEmail } from "../../features/auth/authSlice"
import { enqueueSnackbar } from "../../features/notifier/notifierSlice"
import { ApiError } from "../../model/response"
import { SessionResponse } from "../../model/session"
import isFetchBaseQueryError from "../../util/isFetchBaseQueryError"
import { api } from "./api"

let pemKey = import.meta.env.VITE_PUBLIC_KEY

// Fixes issue with AWS environment variables not supporting new lines
if (pemKey && pemKey.includes("\\n")) {
  pemKey = pemKey.replaceAll("\\n", "\n")
}

const PUBLIC_KEY = forge.pki.publicKeyFromPem(pemKey)

export function encryptPassword(
  password: string,
  timestamp = Date.now().toString(),
) {
  // md5 Password
  const md5 = forge.md.md5.create()
  md5.update(password)
  password = md5.digest().toHex()

  // Add timestamp
  password = `f(${password}):${timestamp}`

  // Encrypt with RSA
  const encrypted = PUBLIC_KEY.encrypt(password, "RSA-OAEP", {
    md: forge.md.sha256.create(),
    mgf1: forge.mgf.mgf1.create(forge.md.sha1.create()),
  })

  // Base 64
  password = forge.util.encode64(encrypted)

  return password
}

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<SessionResponse, { email: string; password: string }>(
      {
        query: ({ email, password }) => {
          const timestamp = Date.now().toString()
          const encrypted = encryptPassword(password, timestamp)

          return {
            url: "/authenticate",
            method: "POST",
            headers: {
              timestamp,
            },
            body: {
              email,
              data: encrypted,
              timestamp,
            },
          }
        },
        onQueryStarted: async ({ email }, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled

            // Invalidate api state so all data is re-fetched
            dispatch(api.util.resetApiState())

            // Set email on successful login
            dispatch(setEmail(email))

            // Notify user
            dispatch(
              enqueueSnackbar({
                message: "Logged in successfully!",
                options: {
                  key: "login_success",
                  variant: "success",
                },
              }),
            )
          } catch (e: any) {
            console.log(e)
            // Notify user
            let message = "An error occurred while logging in."
            let error: ApiError | undefined
            if ("error" in e && isFetchBaseQueryError(e.error)) {
              const data = e.error.data as ApiError
              if (data) {
                error = data

                if (e.error.status === 404) {
                  message =
                    "A user with that email address could not be found..."
                } else if (e.error.status === 401) {
                  message = "Incorrect password."
                } else if (error.error_message) {
                  message = error.error_message
                }
              }
            }

            dispatch(
              enqueueSnackbar({
                message,
                options: {
                  key: "login_error",
                  variant: "error",
                },
              }),
            )
          }
        },
        extraOptions: {
          // Don't retry login requests
          maxRetries: 0,
        },
      },
    ),
  }),
})

export const { useLoginMutation } = authApi

export const {
  endpoints: { login },
} = authApi
