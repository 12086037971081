import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { authApi } from "../../app/services/auth"
import { RootState } from "../../app/store"
import { Session } from "../../model/session"
import { enqueueSnackbar } from "../notifier/notifierSlice"

type State = {
  email: string | null
} & Session

const initialState: State = {
  email: null,
  session_token: null,
}

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string | null>) => {
      state.email = action.payload
    },
    handleLogout: (state) => {
      state.email = null
      state.session_token = null
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(authApi.endpoints.login.matchFulfilled),
      (state, action) => {
        state.session_token = action.payload.session_token
      },
    )
  },
})

export const logout = createAsyncThunk<void, void>(
  `${slice.name}/logout`,
  async (_, { dispatch }) => {
    await dispatch(slice.actions.handleLogout())

    dispatch(
      enqueueSnackbar({
        message: `Logged out successfully!`,
        options: {
          key: "logout_success",
          variant: "success",
        },
      }),
    )
  },
)

const persistConfig = {
  key: slice.name,
  version: 1,
  storage,
}

const { reducer } = slice
export default persistReducer(persistConfig, reducer)

export const { setEmail, handleLogout } = slice.actions

export const selectEmail = (state: RootState) => state.auth.email

export const selectIsAuthenticated = (state: RootState) =>
  !!state.auth.session_token
