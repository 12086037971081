const secondary = {
  50: "#e9e8ff",
  100: "#c7c6ff",
  200: "#9ea1ff",
  300: "#707bff",
  400: "#495aff",
  500: "#0537ff",
  600: "#012df3",
  700: "#001ee6",
  800: "#0005dc",
  900: "#0000cd",
} as const

export default secondary
