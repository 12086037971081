import { Location } from "history"
import React from "react"
import { Router } from "react-router"
import { BrowserRouterProps, NavigationType } from "react-router-dom"
import history from "../util/history"

export function BrowserRouter({ basename, children }: BrowserRouterProps) {
  let [state, setStateImpl] = React.useState({
    action: history.action,
    location: history.location,
  })
  let setState = React.useCallback(
    (newState: { action: NavigationType; location: Location }) =>
      setStateImpl(newState),
    [setStateImpl],
  )

  React.useLayoutEffect(() => history.listen(setState), [setState])

  return (
    <Router
      basename={basename}
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  )
}
