import {
  FetchArgs,
  FetchBaseQueryError,
  retry,
} from "@reduxjs/toolkit/dist/query/react"
import { BaseQueryFn } from "@reduxjs/toolkit/query/react"

const withRetry = <BaseQuery extends BaseQueryFn>(baseQuery: BaseQuery) =>
  retry(
    async (args: string | FetchArgs, api, extraOptions) => {
      const result = await baseQuery(args, api, extraOptions)

      // bail out of re-tries immediately if unauthorized,
      // because we know successive re-retries would be redundant
      const error = result.error as FetchBaseQueryError
      if (error?.status === 401) {
        // Invalid credentials
        retry.fail(error)
      }

      return result
    },
    {
      maxRetries: 2,
    },
  )

export default withRetry
