import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { RootState } from "../../app/store"

type State = {
  navigationOpen: boolean
}

const initialState: State = {
  navigationOpen: true,
}

const slice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setNavigationOpen: (state, action: PayloadAction<boolean>) => {
      state.navigationOpen = action.payload
    },
  },
})

const persistConfig = {
  key: slice.name,
  version: 1,
  storage,
}

const { reducer } = slice
export default persistReducer(persistConfig, reducer)

export const { setNavigationOpen } = slice.actions

export const selectIsNavigationOpen = (state: RootState) =>
  state.general.navigationOpen
