import React from "react"
import { Route, Routes } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import { useAppSelector } from "./app/hooks"
import { storePersistor } from "./app/store"
import ProtectedRoute from "./components/ProtectedRoute"
import { selectIsAuthenticated } from "./features/auth/authSlice"
import Notifier from "./features/notifier/Notifier"
import Theme from "./theme/Theme"

// Global
const Login = React.lazy(() => import("./pages/Login/Login"))
const ErrorPage = React.lazy(() => import("./pages/Error/ErrorPage"))

// Logged in
const Layout = React.lazy(() => import("./components/Layout/Layout"))

const Homepage = React.lazy(() => import("./pages/Homepage/Homepage"))

// Game
const GameList = React.lazy(() => import("./pages/Game/GameList"))
const GameCreate = React.lazy(() => import("./pages/Game/GameCreate"))
const GameEdit = React.lazy(() => import("./pages/Game/GameEdit"))

// Template
const TemplateList = React.lazy(() => import("./pages/Template/TemplateList"))
const TemplateCreate = React.lazy(
  () => import("./pages/Template/TemplateCreate"),
)
const TemplateEdit = React.lazy(() => import("./pages/Template/TemplateEdit"))

// Campaign Wizard
const Wizard = React.lazy(() => import("./pages/Campaign/Wizard"))
const SelectTemplate = React.lazy(
  () => import("./pages/Campaign/SelectTemplate"),
)

// Campaign
const CampaignRequiredRoute = React.lazy(
  () => import("./pages/Campaign/CampaignRequiredRoute"),
)
const CampaignList = React.lazy(() => import("./pages/Campaign/CampaignList"))
const CampaignCreate = React.lazy(
  () => import("./pages/Campaign/CampaignCreate/CampaignCreate"),
)
const CampaignUpdate = React.lazy(
  () => import("./pages/Campaign/CampaignUpdate/CampaignUpdate"),
)

// AdSet
const AdSetRequiredRoute = React.lazy(
  () => import("./pages/Campaign/AdSet/AdSetRequiredRoute"),
)
const AdSetList = React.lazy(() => import("./pages/Campaign/AdSet/AdSetList"))
const AdSetCreate = React.lazy(
  () => import("./pages/Campaign/AdSet/AdSetCreate/AdSetCreate"),
)
const AdSetUpdate = React.lazy(
  () => import("./pages/Campaign/AdSet/AdSetUpdate/AdSetUpdate"),
)

// Ad
const AdRequiredRoute = React.lazy(
  () => import("./pages/Campaign/AdSet/Ad/AdRequiredRoute"),
)
const AdList = React.lazy(() => import("./pages/Campaign/AdSet/Ad/AdList"))
const AdCreate = React.lazy(
  () => import("./pages/Campaign/AdSet/Ad/AdCreate/AdCreate"),
)
const AdUpdate = React.lazy(
  () => import("./pages/Campaign/AdSet/Ad/AdUpdate/AdUpdate"),
)

// Stage
const StageList = React.lazy(
  () => import("./pages/Campaign/AdSet/Ad/Stage/StageList"),
)
const StageCreate = React.lazy(
  () => import("./pages/Campaign/AdSet/Ad/Stage/StageCreate"),
)
const StageUpdate = React.lazy(
  () => import("./pages/Campaign/AdSet/Ad/Stage/StageUpdate"),
)

// Review
const Review = React.lazy(() => import("./pages/Campaign/Review/Review"))

// Creative
const CreativeList = React.lazy(() => import("./pages/Creative/CreativeList"))
const CreativeCreate = React.lazy(
  () => import("./pages/Creative/CreativeCreate"),
)
const CreativeEdit = React.lazy(() => import("./pages/Creative/CreativeEdit"))

function App() {
  const isAuthenticated = useAppSelector(selectIsAuthenticated)

  return (
    <Theme>
      <PersistGate loading={null} persistor={storePersistor}>
        <Notifier>
          <React.Suspense>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  <ProtectedRoute hasPermission={isAuthenticated}>
                    <Layout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<Homepage />} />

                <Route path="games">
                  <Route index element={<GameList />} />
                  <Route path="create" element={<GameCreate />} />
                  <Route path=":gameId" element={<GameEdit />} />
                </Route>

                <Route path="templates">
                  <Route index element={<TemplateList />} />
                  <Route path="create" element={<TemplateCreate />} />
                  <Route path=":templateId" element={<TemplateEdit />} />
                </Route>

                <Route path="campaigns">
                  <Route index element={<CampaignList />} />
                  <Route element={<Wizard />}>
                    <Route path="create">
                      <Route index element={<SelectTemplate />} />
                      <Route path=":templateId" element={<CampaignCreate />} />
                    </Route>
                    <Route
                      path=":campaignId"
                      element={<CampaignRequiredRoute />}
                    >
                      <Route index element={<CampaignUpdate />} />
                      <Route path="adsets">
                        <Route index element={<AdSetList />} />
                        <Route path="create" element={<AdSetCreate />} />
                        <Route path=":adSetId" element={<AdSetRequiredRoute />}>
                          <Route index element={<AdSetUpdate />} />
                          <Route path="ads">
                            <Route index element={<AdList />} />
                            <Route path="create" element={<AdCreate />} />
                            <Route path=":adId" element={<AdRequiredRoute />}>
                              <Route index element={<AdUpdate />} />
                              <Route path="stages">
                                <Route index element={<StageList />} />
                                <Route
                                  path="create"
                                  element={<StageCreate />}
                                />
                                <Route
                                  path=":stageIndex"
                                  element={<StageUpdate />}
                                />
                              </Route>
                            </Route>
                          </Route>
                        </Route>
                      </Route>
                      <Route path="review" element={<Review />} />
                    </Route>
                  </Route>
                </Route>

                <Route path="creatives">
                  <Route index element={<CreativeList />} />
                  <Route path="create" element={<CreativeCreate />} />
                  <Route path=":creativeId" element={<CreativeEdit />} />
                </Route>
              </Route>

              <Route
                path="*"
                element={<ErrorPage error="Page not found..." />}
              />
            </Routes>
          </React.Suspense>
        </Notifier>
      </PersistGate>
    </Theme>
  )
}

export default App
