import {
  BaseQueryArg,
  BaseQueryExtraOptions,
} from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import { BaseQueryFn, FetchBaseQueryError } from "@reduxjs/toolkit/query/react"
import { BaseQueryApi } from "@reduxjs/toolkit/src/query/baseQueryTypes"
import { Mutex } from "async-mutex"
import { handleLogout } from "../../../features/auth/authSlice"
import { enqueueSnackbar } from "../../../features/notifier/notifierSlice"
import { ApiError } from "../../../model/response"

// Don't allow concurrent refreshes
const mutex = new Mutex()

const withTokenExpiration =
  <BaseQuery extends BaseQueryFn>(baseQuery: BaseQuery) =>
  async (
    args: BaseQueryArg<BaseQuery>,
    api: BaseQueryApi,
    extraOptions: BaseQueryExtraOptions<BaseQuery>,
  ) => {
    // Wait for any in-progress refreshes to finish
    await mutex.waitForUnlock()

    // Attempt the query
    let result = await baseQuery(args, api, extraOptions)

    // If no error, return the result
    const error = result.error as FetchBaseQueryError
    if (!error) {
      return result
    }

    // If the error is not a 401, return the result
    if (error.status !== 401) {
      return result
    }

    // If the error is a 401, check if it's a login request and the error message
    const response = error.data as ApiError
    if (
      response.error_location === "/authenticate POST" ||
      response.error_message !== "Authentication failed."
    ) {
      return result
    }

    // If the error is a 401 with the correct error_message, log the user out
    if (mutex.isLocked()) {
      // If mutex is locked, let the other request handle the logout

      await mutex.waitForUnlock()
    } else {
      // Otherwise, lock mutex and log the user out

      const release = await mutex.acquire()

      try {
        api.dispatch(handleLogout())

        // Notify user
        api.dispatch(
          enqueueSnackbar({
            message: "Login session expired, logging you out...",
            options: {
              key: "login_session_expired",
              variant: "error",
            },
          }),
        )

        return result
      } finally {
        release()
      }
    }

    return result
  }

export default withTokenExpiration
