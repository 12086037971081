const kwaleeYellow = {
  50: "#fff9e0",
  100: "#ffedb0",
  200: "#ffe27d",
  300: "#ffd844",
  400: "#ffcb05",
  500: "#ffc400",
  600: "#ffb600",
  700: "#ffa200",
  800: "#ff9000",
  900: "#ff6f00",
} as const

export default kwaleeYellow
